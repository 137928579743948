// @flow

import React, { useMemo, type Node } from 'react'
import { Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import ScrollTo from '../../components/basic/ScrollTo'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../components/basic/Button'
import { Container } from '../../components/basic/Grid'
import baseStyles from './IntroTeaser.scss'

type Props = {
  ...StyleProps,
  image?: string | Node,
  imageCopyright?: string | Node,
  imageConstrain?: boolean,
  title?: string,
  titleCount?: string,
  titleElement?: Node,
  subtitle?: string,
  copy?: string | Node,
  btn?: string,
  btnIcon?: string,
  btnLink?: string,
  aLink?: string,
  btnAnchor?: string,
  fullWidth?: boolean,
  simple?: boolean,
  reversed?: boolean,
  objectFit?: string,
  productImg?: string
}

const IntroTeaser = ({
  styles,
  image,
  title,
  titleCount,
  titleElement,
  subtitle,
  copy,
  btn,
  aLink,
  btnIcon,
  btnLink,
  fullWidth,
  reversed,
  objectFit,
  productImg,
  btnAnchor,
  simple,
  imageConstrain,
  imageCopyright
}: Props) => {
  const button = useMemo(
    () => (
      <Button tag="span" color="accent" iconRight={btnIcon || 'icon-arrow_03-right-medium'}>
        {btn}
      </Button>
    ),
    [btn, btnIcon]
  )

  const innerContent = useMemo(
    () => (
      <>
        <ScrollAnimation className={styles.imageWrap} animateIn="fadeIn" animateOnce>
          {image}
          <span className={styles.imageCopyright}>{imageCopyright}</span>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" animateOnce className={styles.headlines}>
          <div className={styles.titleWrap}>
            {subtitle && <h5 className={styles.subtitle}>{subtitle}</h5>}
            {!titleElement && title && (
              <h2 className={classNames(styles.title, titleCount && styles.hasTitleCount)}>
                {titleCount && <span className={styles.titleCount}>{titleCount}</span>}
                {title}
              </h2>
            )}
            {titleElement}
            {typeof copy === 'string' ? (
              <div className={styles.copy} dangerouslySetInnerHTML={{ __html: copy }} />
            ) : (
              <div className={styles.copy}>{copy}</div>
            )}
            {btn && btnLink && (
              <Link to={btnLink} className={styles.btnLink}>
                {button}
              </Link>
            )}
            {btn && aLink && (
              <div className={styles.btnLink}>
                <a href="mailto:recruiting@freshcells.de" target="_blank" rel="noopener noreferrer">
                  <Button tag="span" color="accent" iconRight={btnIcon || 'icon-arrow_03-right-medium'}>
                    {btn}
                  </Button>
                </a>
              </div>
            )}
            {btn && btnAnchor && (
              <ScrollTo toId={btnAnchor} styles={styles.btnLink}>
                {button}
              </ScrollTo>
            )}
          </div>
        </ScrollAnimation>
      </>
    ),
    [
      image,
      objectFit,
      productImg,
      subtitle,
      titleElement,
      title,
      copy,
      btn,
      btnLink,
      button,
      aLink,
      btnIcon,
      btnAnchor,
      styles
    ]
  )

  return (
    <div
      className={classNames(
        styles.root,
        fullWidth && styles['is-fullWidth'],
        imageConstrain && styles['is-imageConstrain'],
        simple ? styles['is-simple'] : styles['has-background'],
        reversed && styles['is-reversed']
      )}
    >
      {fullWidth ? (
        <Container styles={styles.container} fluid>
          {innerContent}
        </Container>
      ) : (
        innerContent
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IntroTeaser)
