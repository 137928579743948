// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../IntroTeaser'
import ClientOverview from '../Clients/ClientOverview'

import messages from './PartnerPage.lang'
import baseStyles from './PartnerPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage src="../../images/pictures/partner_intro.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/partner_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const PartnerPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="office gallery" />
      <IntroTeaser
        title={formatMessage(messages.introTitle)}
        copy={
          <>
            <FormattedMessageSanitized tagName="p" {...messages.introInfo} />
            <h3 className={styles.subtitle}>{formatMessage(messages.introSubtitle)}</h3>
            <p>{formatMessage(messages.introInfo2)}</p>
          </>
        }
        image={IntroImage}
        btn={formatMessage(messages.btn)}
        btnLink="/contact/"
        fullWidth
      />
      <ClientOverview />
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PartnerPage)
