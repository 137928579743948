import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Partner',
  introTitle: 'We seek partners not clients, because your success is our goal',
  introInfo: ' freshcells has been enjoying success in the digital industry for more than 10 years. We are looking for people with a fresh perspective and innovative ideas: business owners, IT companies, professional programmers, students or indeed anyone. If this sounds like you, please get in touch by filling out this form, and we will get back to you as soon as possible.',
  introSubtitle: 'Success together',
  introInfo2: 'We work with a wide range of businesses. It\'s our strong belief that our long-term collaborations and partnerships are the basis for this. We are working hard to continue our success over the coming next decades. Do you want to benefit from our experience, fair rates, and a long-term relationship? Get in touch to learn more.',
  btn: 'Become a partner'
})
